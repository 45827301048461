/* https://www.npmjs.com/package/@flaticon/flaticon-uicons */
@import '~@flaticon/flaticon-uicons/css/bold/all';
@font-face {
  font-family: 'Jua';
  src: url('assets/fonts/Jua/Jua-Regular.woff') format('woff');
  font-display: swap;
  unicode-range: U+21-39, U+3C-3E, U+40-5F, U+61-7E, U+20A9, U+AC00-D7A3;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('assets/fonts/Noto_Sans_KR/NotoSansKR-VariableFont_wght.woff') format('woff-variations');
  src: url('assets/fonts/Noto_Sans_KR/NotoSansKR-VariableFont_wght.woff') format('woff') tech('variations');
  font-weight: 400 900;
  font-stretch: 25% 151%;
  font-display: swap;
  unicode-range: U+21-39, U+3C-3E, U+40-5F, U+61-7E, U+20A9, U+AC00-D7A3;
}

body {
  padding: 0 !important;
  margin: 0;
  font-size: 16px;

  /* prevent text selection */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* prevent button highlight */
  -webkit-tap-highlight-color: transparent;

  font-family: 'Noto sans';
}

h1,
h2,
h3,
h4,
h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  box-sizing: border-box;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

div {
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}
